@import 'ui/variables.module.scss';

.layoutMain {
  height: 100vh;
  height: calc(var(--vh) * 100);
  display: flex;
  flex-direction: column;
  font-family: $font-body;
  color: $body-text;

  .content {
    flex-grow: 1;
    overflow: auto;
    background-color: $body-bg;
  }
}
@import 'ui/variables.module.scss';

.heroContainer {
  width: 100%;
  height: calc(100vh - #{$header-size} - #{$footer-size});
  overflow: hidden;
  position: relative;

  .background {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    
    img {
      position: absolute;
      z-index: 0;
      object-fit: cover;
      min-width: 100%;
      min-height: 100%;
      bottom: 0;
    }
  }

  .title {
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 350px;
    width: 100%;

    svg {
      width: 100%;
    }
  }
}
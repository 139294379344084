// COLORS
$dark-grey: #303030;
$white: #FFFFFF;
$light-grey: #FAFAFA;
$black: #1B1B1B;
// INTENTION
$surface-bg: $dark-grey;
$surface-text: $white;
$body-text: $black;
$body-bg: $light-grey;
$title-text: $black;
// SPACING
$padding-sm: 12px;
$padding-md: 24px;
$padding-lg: 48px;
// FONTS
$font-title: 'Orator Std';
$font-body: 'Source Sans Pro';
// LAYOUT
$header-size: 64px;
$footer-size: 48px;
$max-width-layout: 1240px;
// BREAK-POINT
$mobile-nav: 470px;

:export {
  mobileNav: $mobile-nav;
}
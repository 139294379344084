@import 'ui/variables.module.scss';

.burgerMenuContainer {
  width: 40px;
  height: 40px;
  background-color: $surface-bg;
  border: 1px solid $surface-text;
  border-radius: 10px;
  padding: 5px;
  z-index: 501;

  .burgerMenuItem {
    display: block;
    height: 15%;
    width: 100%;
    background-color: $surface-text;
    transform-origin: center;
    transform: translateY(0) rotate(0);
    transition: 0.3s;

    &:not(:last-child) {
      margin-bottom: 20%;
    }
  }

  &.active {
    .burgerMenuItem {
      &:nth-child(1) {
        transform: translateY(10px) rotate(-45deg);
        transition: 0.3s;
      }

      &:nth-child(2) {
        width: 0;
        transition: 0.3s;
      }

      &:nth-child(3) {
        transform: translateY(-10px) rotate(45deg);
        transition: 0.3s;
      }
    }
  }
}
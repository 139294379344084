@import 'ui/variables.module.scss';

.space {
  display: flex;
  min-width: 0;


  &.justify-start {
    justify-content: flex-start;
  }
  
  &.justify-end {
    justify-content: flex-end;
  }

  &.justify-center {
    justify-content: center;
  }

  &.justify-space-around {
    justify-content: space-around;
  }

  &.justify-space-between {
    justify-content: space-between;
  }

  &.align-start {
    align-items: flex-start;
  }

  &.align-middle {
    align-items: center;
  }

  &.align-end {
    align-items: flex-end;
  }

  &.align-baseline {
    align-items: baseline;
  }

  &.dir-horizontal {
    flex-direction: row;

    &.size-small {
      & > *:not(:last-child) {
        margin-right: $padding-sm;
      }
    }

    &.size-medium {
      & > *:not(:last-child) {
        margin-right: $padding-md;
      }
    }

    &.size-large {
      & > *:not(:last-child) {
        margin-right: $padding-lg;
      }
    }
  }

  &.dir-reverse-horizontal {
    flex-direction: row-reverse;

    &.size-small {
      & > *:not(:last-child) {
        margin-left: $padding-sm;
      }
    }

    &.size-medium {
      & > *:not(:last-child) {
        margin-left: $padding-md;
      }
    }

    &.size-large {
      & > *:not(:last-child) {
        margin-left: $padding-lg;
      }
    }
  }

  &.dir-vertical {
    flex-direction: column;

    &.size-small {
      & > *:not(:last-child) {
        margin-bottom: $padding-sm;
      }
    }

    &.size-medium {
      & > *:not(:last-child) {
        margin-bottom: $padding-md;
      }
    }

    &.size-large {
      & > *:not(:last-child) {
        margin-bottom: $padding-lg;
      }
    }
  }

  &.dir-reverse-vertical {
    flex-direction: column-reverse;

    &.size-small {
      & > *:not(:last-child) {
        margin-top: $padding-sm;
      }
    }

    &.size-medium {
      & > *:not(:last-child) {
        margin-top: $padding-md;
      }
    }

    &.size-large {
      & > *:not(:last-child) {
        margin-top: $padding-lg;
      }
    }
  }
}
@import './fonts.scss';


* {
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}

a {
  text-decoration: none;
  color: inherit;
  white-space: pre-line;
}

p {
  margin: 0;
  white-space: pre-line;
}

img {
  max-width: 100%;
}
@font-face {
  font-family: 'Orator Std';
  src: url('./fonts/OratorStd.eot');
  src: url('./fonts/OratorStd.eot?#iefix') format('embedded-opentype'),
      url('./fonts/OratorStd.woff2') format('woff2'),
      url('./fonts/OratorStd.woff') format('woff'),
      url('./fonts/OratorStd.ttf') format('truetype'),
      url('./fonts/OratorStd.svg#OratorStd') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('./fonts/SourceSansPro-Regular.eot');
  src: url('./fonts/SourceSansPro-Regular.eot?#iefix') format('embedded-opentype'),
      url('./fonts/SourceSansPro-Regular.woff2') format('woff2'),
      url('./fonts/SourceSansPro-Regular.woff') format('woff'),
      url('./fonts/SourceSansPro-Regular.ttf') format('truetype'),
      url('./fonts/SourceSansPro-Regular.svg#SourceSansPro-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


@import 'ui/variables.module.scss';

.landingBlock {
  min-height: calc(var(--vh, 1vh) * 100 - #{$header-size} - #{$footer-size});
}

.withPadding {
  padding: $padding-lg;
  max-width: $max-width-layout;
  width: 100%;
  margin: auto;
}